import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Universal Tablet UI Kit | UI Kit For Tablet | Uizard"
    category="Tablet Templates"
    url="/templates/tablet-templates/universal-tablet-ui-kit/"
    metaDescription="Whatever you are designing, our universal tablet UI kit is here to fast-track your design flow. Packed with useful components, sign up today!"
    description="
    h2:Uizard's universal tablet UI kit
    <br/>
    Whether you are working on a medium fidelity mockup or a <a:https://uizard.io/prototyping/>UI prototype</a> for your tablet app idea, our universal tablet UI kit is here to make your design journey a whole lot easier. Design faster than ever before with our drag-and-drop components and streamline the way you design.
    <br/>
    h3:Endless customization with premade UI components for tablet
    <br/>
    Customization of your tablet app design is easy with our UI kit for tablet. The kit comes with a raft of <a:https://uizard.io/templates/component-templates/>UI components</a> that make designing for tablet easier than ever before. Simply open a project and bring your tablet idea to life.
    <br/>
    h3:Collaborate on your tablet app design like never before
    <br/>
    Invite team members; get feedback from stakeholders; iterate collaboratively. Uizard empowers you to collaborate in real-time with colleagues, teammates, and even external stakeholders. Tablet app design has never been so easy. Try Uizard’s smart sharing and feedback features today.
    "
    pages={[
      "All the pre-built UI components and screens for your tablet design including e-commerce screens, finance mockups, and data visualization",
      "Smart customization allowing you to adapt and update your designs to suit your tablet project",
    ]}
    projectCode="6or8L3elLnholyOzeo7P"
    img1={data.image1.childImageSharp}
    img1alt="universal tablet ui kit cover"
    img2={data.image2.childImageSharp}
    img2alt="universal tablet ui kit onboarding components"
    img3={data.image3.childImageSharp}
    img3alt="universal tablet ui kit profile components "
    img4={data.image4.childImageSharp}
    img4alt="universal tablet ui kit screen components"
    img5={data.image5.childImageSharp}
    img5alt="universal tablet ui kit ecom components"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/universal-tablet-ui-kit/tablet-ui-kit-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/universal-tablet-ui-kit/tablet-ui-kit-onboarding.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/universal-tablet-ui-kit/tablet-ui-kit-profile.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/universal-tablet-ui-kit/tablet-ui-kit-screens.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/universal-tablet-ui-kit/tablet-ui-kit-ecommerce.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
